.main {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    margin: 0 auto;
    width: 100%;
    max-width: 87.5rem;
}

.header {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.header__links {
    display: flex;
    align-items: center;
}

.header__links > *:not(:last-child) {
    margin-right: 1.5rem;
}

#logo {
    width: auto;
    height: 2rem;
}

.request-access {
    font-size: 1rem;
    color: var(--text);
    background-color: var(--accent);
    border-radius: .25rem;
    padding: .75rem 1.5rem;
    border: none;
    outline: none;
    font-weight: 600;
    cursor: pointer;
    transition: all .25s ease-in;
    position: relative;
}

.request-access:disabled {
    background-color: var(--dark-gray);
    cursor: not-allowed;
}

.popup__notification {
    display: none;
}

.error, .success {
    display: inline-block;
    color: maroon;
}

.success {
    color: darkgreen;
}

.header__enter {
    display: none;
}

.request-access:hover {
    box-shadow: 0 0 5px 2px var(--green);
}

.footer {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-end;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: var(--gray);
    border-radius: 1.25rem;
    width: 100%;
}

.content__body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: center;
    align-items: flex-start;
    padding: 1.5rem;
    position: relative;
    width: 100%;
}

.content__footer {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    background-color: white;
    width: 100%;
    padding: 1.5rem;
    border-radius: 0 0 1.25rem 1.25rem;
    z-index: 1;
}

.content__scopes {
    display: flex;
    flex-direction: column;
}

.content__scopes > span {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
    opacity: 0;
    font-family: var(--montserrat);
}

.content__scopes span:first-child {
    animation: .5s ease-out 0s 1 custom;
    animation-fill-mode: forwards;
    background: linear-gradient(66.29deg, var(--yellow) -59.84%, var(--green) 8.29%, var(--accent) 76.42%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: var(--yellow);
}

.content__scopes span:nth-child(2) {
    animation: .5s ease-out .2s 1 custom;
    animation-fill-mode: forwards;
}

.content__scopes span:nth-child(3) {
    animation: .5s ease-out .4s 1 custom;
    animation-fill-mode: forwards;
}

.content__description {
    margin-top: 2rem;
    font-size: 1.2rem;
    line-height: 1;
    color: rgb(72, 72, 72);
    opacity: 0;
    animation: .5s ease-out .6s 1 custom;
    animation-fill-mode: forwards;
}

.request-access--content {
    opacity: 0;
    animation: .5s ease-out .8s 1 custom;
    animation-fill-mode: forwards;
}

.footer {
    margin-top: 2.5rem;
}

.footer .link img {
    height: 1.5rem;
    width: auto;
}

.address {
    display: flex;
    flex-direction: column;
    margin-top: .25rem;
    font-size: .75rem;
}

.content__mockup {
    display: none;
}

.content__partners {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    place-items: center;
    gap: 1rem;
}

.content__partner img {
    width: 100%;
    height: auto;
}

.content__partner {
    display: block;
    opacity: 0;
    padding: 0;
    margin: 0;
}

.content__partner:hover {
    transform: scale(.95) rotateX(10deg) rotateY(-20deg);
}

.content__partner:first-child {
    animation: .5s ease-out 0s 1 skewAndScale;
    animation-fill-mode: forwards;
}

.content__partner:nth-child(2) {
    animation: .5s ease-out .2s 1 skewAndScale;
    animation-fill-mode: forwards;
}

.content__partner:nth-child(3) {
    animation: .5s ease-out .4s 1 skewAndScale;
    animation-fill-mode: forwards;
}

.content__partner:nth-child(4) {
    animation: .5s ease-out .6s 1 skewAndScale;
    animation-fill-mode: forwards;
}

.content__partner:nth-child(5) {
    animation: .5s ease-out .8s 1 skewAndScale;
    animation-fill-mode: forwards;
}
.content__partner:nth-child(6) {
    animation: .5s ease-out 1s 1 skewAndScale;
    animation-fill-mode: forwards;
}
.content__intro {
    margin-top: 7rem;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.content__heading {
    font-size: 1.75rem;
    text-align: center;
    font-family: var(--montserrat);
}

.content__intro__description {
    width: 100%;
    max-width: 20rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 12rem;
}

.content__intro__pad {
    width: 20rem;
    max-width: 90%;
    height: auto;
    position: absolute;
    bottom: -8rem;
}

.content__abilities {
    background-color: rgb(12, 36, 32);
    width: 100%;
    padding: 2rem 0;
    z-index: 1;
}

.content__abilities__inner {
    margin: 0 auto;
    width: 100%;
    max-width: 60rem;
    display: grid;
    padding: 0 1.5rem;
    gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
}

.content__ability__header, .content__ability__header--logo {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.content__ability__header--logo {
    justify-content: flex-end;
}

.content__ability__header--logo .cw-logo {
    height: 3rem;
    width: auto;
}

.content__ability, .content__ability--special {
    background-color: rgb(24, 64, 55);
    border-radius: 1.25rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    aspect-ratio: 1/1;
    margin: 0;
}

.content__ability--special {
    background-color: rgb(225, 255, 160);
}

.content__ability--special .request-access {
    margin: .8rem 0 2.2rem 0;
}

.content__ability__image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: rgb(49, 115, 95);
}

.content__ability__image img {
    height: 1.75rem;
    width: auto;
}

.content__ability__title {
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-family: var(--montserrat);
}

.content__ability--special .content__ability__title {
    color: var(--text);
}

.content__ability__content {
    font-weight: 500;
    font-size: 1rem;
    color: rgb(240, 240, 240);
    min-height: 3.75rem;
}

.content__ability__content strong {
    color: rgb(225, 255, 160);
}

.content__ability__description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 auto;
}

.content__pricing__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem 0;
}

.content__price {
    display: flex;
    flex-direction: column;
    border-radius: 1.25rem;
    padding: 1.5rem;
    align-items: center;
}

.content__price__price {
    font-family: var(--montserrat);
    font-size: 2rem;
}

.content__price strong {
    text-align: center;
}

.content__price .request-access {
    margin: 1.5rem 0 .5rem 0;
}

.content__price--start .request-access {
    background-color: var(--green);
    color: white;
}

.content__price--start {
    background-color: rgb(225, 255, 160);
}

.content__price--growth {
    background-color: var(--green);
    color: white;
}

.content__price--growth .request-access {
    background-color: rgb(225, 255, 160);
    color: var(--text);
}

.content__price--enterprise .request-access {
    background-color: var(--green);
    color: white;
}

.content__price ul {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.content__price ul > li {
    line-height: 1.296;
}

.content__price__footer {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    align-items: center;
}

.content__price__disclaimer {
    font-size: .875rem;
    margin-top: .25rem;
}

.content__price--enterprise {
    background-color: var(--gray);
}

.content__price h2 {
    text-align: center;
    font-family: var(--montserrat);
}

.popup, .popup--privacy {
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    content: '';
    background: rgba(0,0,0,0.85);
    z-index: 10;
}

.popup__content {
    max-width: 25rem;
    width: 100%;
    padding: 2rem;
    transform: translate(-50%, -50%) scale(0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 2px 16px rgba(0,0,0,0.5);
    border-radius: 1.25rem;
    background: var(--background);
    text-align: center;
}

.popup--privacy .popup__content {
    max-width: 60rem;
    overflow-y: scroll;
    max-height: calc(100vh - 4rem);
    text-align: left;
}

.popup__disclaimer {
    font-size: .75rem;
    text-align: left;
    line-height: 1;
    color: var(--dark-gray);
}

.popup__content .content__heading {
    font-size: 1.5rem;
}

.popup__content__form {
    display: flex;
    flex-direction: column;
}

.popup__content__form > *:not(:last-child) {
    margin-bottom: .5rem;
}

.form-input {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--text);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-input:focus {
    outline: 2px solid var(--yellow);
}

.transform-in, .transform-out {
    display: block;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.transform-in {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
}

.transform-out {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
}

.btn--privacy {
    background: none;
    border: none;
    padding: 0;
    font-size: .75rem;
    margin-top: 1rem;
    cursor: pointer;
}

.btn--privacy:hover {
    text-decoration: underline;
}

.footer__right {
    display: flex;
    align-items: flex-end;
}

.footer__clockwork {
    height: 1rem;
    width: auto;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {

    .footer {
        flex-direction: row;
        justify-content: space-between;
    }

    .content__pricing__list {
        grid-template-columns: repeat(3, 1fr);
        gap: 0 2rem;
    }

    .content__heading {
        font-size: 3rem;
    }

    .content__intro__description {
        max-width: 30rem;
        font-size: 1.25rem;
    }

    .content__intro__pad {
        width: 40rem;
        bottom: -16rem;
    }

    .content__abilities__inner {
        margin: 0 auto;
        width: 100%;
        max-width: 68rem;
        display: grid;
        padding: 0 1.5rem;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .content__intro__description {
        width: 100%;
        max-width: 20rem;
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 20rem;
    }

    .main {
        padding: 2.5rem;
    }

    .header__enter {
        display: block;
    }

    .content__body {
        padding: 0 4rem;
        min-height: 30rem;
    }

    .content__scopes > span {
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.125;
        opacity: 0;
    }

    .content__description {
        font-size: 1.75rem;
    }

    .content__footer {
        padding: 1rem 4rem;
    }

    .content__mockup {
        display: block;
        position: absolute;
        bottom: -2rem;
        right: 8rem;
        height: 25rem;
        width: auto;
        z-index: 0;
        animation: .75s ease-out 0s 1 slideInFromBottom;
    }

    .content__partner img {
        width: 8rem;
    }

    .content__partners {
        grid-template-columns: repeat(var(--items), 1fr);
        place-items: center;
        margin: 0 auto;
        gap: 0 2rem;
    }

}