*, :after, :before {
    box-sizing: border-box;
}

*::selection {
    color: var(--yellow);
}

*:root {
    --yellow: rgb(238, 229, 60);
    --accent: rgb(200, 255, 28);
    --light: rgb(225, 255, 160);
    --background: rgb(245, 245, 245);
    --yellow-50: rgba(238, 229, 60, .5);
    --text: rgb(28, 28, 28);
    --green: rgb(39, 68, 49);
    --purple: rgb(95, 37, 159);
    --purple-50: rgba(95, 37, 159, .5);
    --gray: rgb(229, 229, 229);
    --dark-gray: rgb(102, 102, 102);
    --golos: "Golos Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --montserrat: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
    font-family: var(--golos);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text);
    background-color: var(--background);
    padding: 0;
    margin: 0 auto;
    height: 100vh;
    width: 100%;
    font-weight: 400;
}

#root {
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
    color: var(--green);
    font-weight: 500;
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(2rem);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes custom {
    0% {
        opacity: 0;
        transform: translateY(1rem) scale(.95);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

@keyframes skewAndScale {
    0% {
        opacity: 0;
        transform: scale(.95) rotateX(10deg) rotateY(-20deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) rotateX(0deg) rotateY(0deg);
    }
}